.menuitem {
    margin-left: auto;
    max-width: calc(100% - 185px);
    right: 0;
    width: 100%;
    padding: 24px;
    margin-top: 67px;
}

.menuitemsinner img {
    max-width: 332px;
    width: 100%;
}

.menuitemleftright {
    /* display: flex; */
    gap: 24px;
    padding-right: 15px;
    height: calc(100vh - 120px);
    overflow: auto;
}

.menu-item {
    /* max-width: 350px; */
    width: 100%;
    display: flex;
    padding: 8px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 8px;
    border: 1px solid var(--Neutral-200, #E5E7EB);
    height: calc(100vh - 120px);
    position: sticky;
    top: 0;
}

.menu-item-full {
    display: flex;
    height: 854px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 1px solid var(--Neutral-200, #E5E7EB);
    width: 100%;
    flex-direction: column;
}

.menu-item p {
    color: var(--Neutral-400, #9CA3AF);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    margin-bottom: 0;
}

.menu-item h2 {
    color: var(--Neutral-800, #1F2937);
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 0;
}

.rate img {
    width: 15px !important;
}

.veg img {
    width: 26px !important;
}

.settle {
    margin-top: 32px;
    width: 100%;
    margin-bottom: 32px;
}

.settle.menuitem-plus a {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
}

.rate {
    display: flex;
    padding: 1.5px;
    align-items: flex-start;
    gap: 1.5px;
    border-radius: 3px;
    border: 0.45px solid var(--warning-500-main, #F59E0B);
    background: var(--Warning-50, #FFFBEB);
}

.kcal {
    display: flex;
    padding: 1.5px 3px;
    align-items: center;
    gap: 3px;
    border-radius: 3px;
    border: 0.45px solid var(--Secondary-Slate-500, #64748B);
    background: var(--Neutral-50, #F9FAFB);
}

.kcal img {
    width: 14px;
}

.kcal h4 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: stretch;
    color: var(--Neutral-500, #6B7280);
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    margin-bottom: 0;
}

.rate-kcal {
    display: flex;
    align-items: center;
    gap: 8px;
}

.itemrate {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 8px;
    margin-bottom: 6px;
}

.veg {
    display: flex;
}

.menu-name h2 {
    color: var(--Neutral-800, #1F2937);

    /* Label/Large/Semi Bold */
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    /* 112.5% */
    margin-bottom: 6px;
}

.menu-name h6 {
    color: var(--Neutral-600, #4B5563);

    /* Label/XSmall/Semibold */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
    /* 140% */
    margin-bottom: 6px;
}

.menu-name p {
    overflow: hidden;
    color: var(--Neutral-400, #9CA3AF);
    text-overflow: ellipsis;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 15px */
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
}

.menuitems {
    display: flex;
    padding: 4px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    border-radius: 6px;
    border: 1px solid var(--Neutral-100, #F3F4F6);
    background: var(--Generic-White, #FFF);
}

.menuitemsinner img {
    border-radius: 8px;
}

.menuitemsall {
    display: grid;
    flex-direction: row;
    gap: 8px;
    overflow: scroll;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.menuitemsall::-webkit-scrollbar {
    display: none;
}

.menu-item-full h3 {
    color: var(--primary-500-main, #71917F);

    /* Label/Large/Semi Bold */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    /* 112.5% */
    margin-bottom: 0;
    margin-top: 16px;
}



/* nextpage css start */
.menuitemfullscreen {
    width: 100%;
}
.menu-item-fullscreen {
    max-width: 1326px;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.menu-item-fullscreen .mainitemimage{
    height: 500px;
    object-fit: cover;
}

.menuitem-fullscreen-left {
    max-width: 450px;
    width: 100%;
    display: flex;
    padding: 8px;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 6px;
    border: 1px solid var(--Neutral-200, #E5E7EB);
    height: max-content;
}

.menuitem-fullscreen-right {
    border-radius: 6px;
    border: 1px solid var(--Neutral-100, #F3F4F6);
    display: flex;
    width: 100%;
    padding: 8px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    width: 100%;
    margin-bottom: 5px;
}
.adonoption-group {
    width: calc(100% - 450px);
}
.inputWithIcon input[type="text"] {
    padding-left: 40px;
}

.inputWithIcon {
    position: relative;
}

/* .inputWithIcon i {
    position: absolute;
    left: 0;
    top: 8px;
    padding: 9px 8px;
    color: #aaa;
    transition: 0.3s;
} */

/* .inputWithIcon input[type="text"]:focus+i {
    color: dodgerBlue;
}

.inputWithIcon.inputIconBg i {
    background-color: #aaa;
    color: #fff;
    padding: 9px 4px;
    border-radius: 4px 0 0 4px;
}

.inputWithIcon.inputIconBg input[type="text"]:focus+i {
    color: #fff;
    background-color: dodgerBlue;
} */

.inputWithIcon img {
    width: auto;
    height: auto;
    position: absolute;
    left: 4px;
    top: 7px;
}

.description {
    position: relative;
}

p.count {
    position: absolute;
    top: 0;
    right: 0;
    padding: 8px;
}

.menuitem-fullscreen-left label {
    color: var(--Neutral-900, #111827);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
}

.menuitem-fullscreen-left input {
    display: flex;
    padding: 8px 12px;
    align-items: center;
    border-radius: 8px;
    border: 1px solid var(--Neutral-200, #E5E7EB);
    width: 100%;
    margin-bottom: 12px;
}

.menuitem-fullscreen-left textarea {
    display: flex;
    padding: 8px 12px;
    align-items: center;
    border-radius: 8px;
    border: 1px solid var(--Neutral-200, #E5E7EB);
    width: 100%;
}

.prize {
    flex-direction: row;
    align-items: flex-start;
    gap: 8px;
    margin-top: 0;
    
}

.input-flex {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    margin-top: 12px;
}

.input img {
    position: absolute;
    top: 47%;
    left: 1%;
}

.input {
    position: relative;
    width: 50%;
}

.prize label {
    margin-bottom: 0;
}

.description {
    width: 100%;
}

.prize-kcal {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    margin-top: 12px;
}

.inputWithIcon input {
    width: 152%;
}

.inputWithIcon input {
    padding-left: 40px;
}

.rating h5 {
    color: var(--Neutral-900, #111827);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    margin-bottom: 8px;
}

.rating img {
    margin-right: 6px;
}

.rating {
    margin-top: 12px;
}

.add h5 {
    color: var(--Neutral-800, #1F2937);
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    margin-bottom: 2px;
}

.add p {
    color: var(--Neutral-400, #9CA3AF);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    margin-bottom: 0;
}
/* .menuitemfullscreen{
    width: 100%; */
/* } */
.menuitemfullscreeninfo {
    display: flex;
    gap: 8px;
    margin-top: 16px;
}
.search-main {
    width: 100%;
    position: relative;
    margin-top: 20px;
}

.search-main input {
    width: 100%;
    border: 1px solid #0005 !important;
    border-radius: 7px;
    padding: 6px 30px 6px 10px;
    box-shadow: none !important;
    outline: none !important;
}

.search-main a {
    position: absolute;
    top: 48%;
    transform: translateY(-50%);
    right: 8px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    z-index: 99;
}
.add-bg {
    display: flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 11px;
    margin-bottom: 10px;
    align-self: stretch;
    border-radius: 6px;
    border: 1px solid var(--Neutral-200, #E5E7EB);
    background: var(--Neutral-50, #F9FAFB);
}

.inputWithIcon.addprize input {
    width: 218px;
}

.prize input {
    display: flex;
    padding: 4.46px 6.69px;
    align-items: center;
    gap: 6.69px;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 4.46px;
    border: 0.557px solid var(--Neutral-200, #E5E7EB);
    padding-left: 30px;
}

.inputWithIcon.addprize img {
    top: 8px;
}

.addmenu img {
    width: 20px;
}

.addinfo {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

a.btngreen.continue.lightgreen {
    background: #E6F8F2;
    color: #71917F;
}

.settle.menuitem-plus.greenbtn {
    margin-top: 16px;
    margin-bottom: 0;
}

.btns {
    display: flex;
    align-items: center;
    gap: 16px;
}

a.btngreen.continue.deletebtn {
    display: flex;
    width: 161px;
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 8px;
    border: 1px solid var(--Destructive-200, #FECACA);
    background: var(--Destructive-50, #FEF2F2);

    /* Drop shadow/Small */
    box-shadow: 0px 2px 6px 0px rgba(16, 24, 40, 0.06);
    color: var(--destructive-500-main, #EF4444);
    text-align: center;

    /* Label/Medium/Medium */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    /* 114.286% */
}

.deleteicons {
    width: auto;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

/* ToggleSwitch.css */
.toggle-switch {
    position: relative;
    display: inline-block;
    width: 55px;
}

.toggle-switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;
    width: 48px;
    height: 24px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 2px;
    bottom: 3px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
}

input:checked+.slider {
    background-color: #6e8d7c;
}

input:checked+.slider:before {
    transform: translateX(26px);
}

.toggle-item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 20px;
    border-bottom: 1px solid var(--Neutral-200, #E5E7EB);
}

.toggle-item h5 {
    color: var(--Neutral-800, #1F2937);
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 112.5% */
    margin-bottom: 0;
}

.toggle-item.last-item {
    border: none;
}

.add {
    margin-bottom: 16px;
}



/* MenuToggle.css */
.menu-container {
    position: relative;
    display: inline-block;
}

.addmenu {
    cursor: pointer;
}

.menu {
    position: absolute;
    top: 0;
    left: -220px;
    /* Adjust this value to position the menu to the left of the icon */
    width: 210px;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    padding: 10px;
    list-style-type: none;
    margin: 0;
    padding: 0;
    z-index: 1000;
}

.menu li {
    padding: 10px;
    border-bottom: 1px solid #ddd;
    display: flex;

}

.menu li:last-child {
    border-bottom: none;
}

.menu li:hover {
    background-color: #f0f0f0;
}

.addmenu img {
    display: block;
}

.red a{
    color: var(--Destructive-600, #DC2626);
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    align-items: center;
    gap: 6px;
    text-decoration: none;
    list-style: none;
}

.text {
    color: var(--Neutral-800, #1F2937);
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    /* 112.5% */
}

.modal-dialog {
    margin: auto !important;
}

.modal-title img {
    border-radius: 24px;
    background: var(--Primary-50, #E6F8F2);
    padding: 8px;
}

.modal-header {
    margin-bottom: 20px;
}

.modal-body h4 {
    color: var(--Neutral-900, #111827);

    /* Paragraph/Large/Medium */
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    /* 155.556% */
    margin-bottom: 8px;
}

.modal-body p {
    color: var(--Neutral-500, #6B7280);

    /* Paragraph/Small/Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
    margin-bottom: 0;
}

.modal-content {
    border-radius: 8px;
    background: var(--Generic-White, #FFF) !important;

    /* Drop shadow/XLarge */
    box-shadow: 0px 20px 40px -8px rgba(16, 24, 40, 0.05), 0px 20px 40px -8px rgba(16, 24, 40, 0.10);
    padding: 24px;
    max-width: 450px;
    width: 100%;
}

.modal-footer {
    border: none !important;
    padding: 0 !important;
    margin-top: 32px;
}

.input.popupinput label {
    color: var(--Neutral-900, #111827);

    /* Label/Medium/Medium */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    /* 114.286% */
    margin-bottom: 8px;
}

.input.popupinput {
    margin-top: 32px;
}

.input.popupinput {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.input.popupinput input {
    border-radius: 8px;
    border: 1px solid var(--Neutral-200, #E5E7EB);
    background: var(--Generic-White, #FFF);
    padding: 8px 12px 8px 34px;
}

.input.popupinput img {
    top: 52%;
}

button.btn.btn-primary {
    border-radius: 8px;
    border: 1px solid var(--primary-500-main, #71917F);
    background: var(--primary-500-main, #71917F);

    /* Drop shadow/XSmall */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04), 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
    padding: 10px 20px;
    margin: 0;
}

.btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active {
    border: 1px solid var(--primary-500-main, #71917F) !important;
    background: #5d7768 !important;
}

.menu-container .dropdown-toggle {
    border: none !important;
    background: none !important;
    padding: 0 5px;
}

.menu-container .dropdown-toggle::after {
    display: none;
}